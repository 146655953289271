import { collection, query, orderBy } from 'firebase/firestore';

import type { Cycle } from '@tn/shared';
import { defineStore } from 'pinia';

const REPORT_OPEN_DATE = 20;

export const useCyclesStore = defineStore('cycles', () => {
  const db = useFirestore();

  const cyclesRef = computed(() => {
    return query(collection(db, 'cycles'), orderBy('startDate', 'asc'));
  });

  const cycles = useCollection<Cycle>(cyclesRef, {
    ssrKey: 'cycles',
  });

  const isLoading = computed(() => cycles.pending.value);

  const getCycleFromMsTimestamp = (msTimestamp: number): Cycle | null => {
    return (
      cycles.value.find(
        (cycle) =>
          cycle.startDate.toMillis() <= msTimestamp &&
          cycle.endDate.toMillis() >= msTimestamp
      ) || null
    );
  };

  const getRelativeToCycle = (
    cycle: Cycle,
    relativeCycleNumber: number
  ): Cycle | null => {
    const startPointCycleIndex = cycles.value.findIndex(
      (c) => c.id === cycle.id
    );

    if (startPointCycleIndex < 0) {
      return null;
    }

    // assuming a startDate asc ordered list
    const targetCycleIndex = startPointCycleIndex + relativeCycleNumber;
    return cycles.value?.[targetCycleIndex] || null;
  };

  const currentCycle = computed(() =>
    getCycleFromMsTimestamp(useNow().getTime())
  );
  const nextCycle = computed(() =>
    currentCycle.value ? getRelativeToCycle(currentCycle.value, +1) : null
  );
  const previousCycle = computed(() =>
    currentCycle.value ? getRelativeToCycle(currentCycle.value, -1) : null
  );

  const isReviewPeriodOpen = computed(() => {
    const now = useNow().getTime();

    return (
      !!currentCycle.value &&
      now >= currentCycle.value.reviewStartDate.toMillis() &&
      now < currentCycle.value.endDate.toMillis()
    );
  });

  const isReportPeriodOpened = computed(() => {
    const now = new Date(useNow().getTime());
    const nowUTC = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      )
    );

    const year = now.getFullYear();
    const month = now.getMonth();
    const reportOpenDate = new Date(
      Date.UTC(year, month, REPORT_OPEN_DATE, 0, 0, 0)
    );

    return nowUTC >= reportOpenDate;
  });

  const fetchCycles = async () => {
    if (cycles.data.value.length < 1) {
      await cycles.promise.value;
    }

    return cycles.value;
  };

  const getCurrentCycle = async () => {
    await cycles.promise.value;

    return currentCycle.value;
  };

  return {
    cycles,
    fetchCycles,
    currentCycle,
    nextCycle,
    previousCycle,
    isLoading,
    isReviewPeriodOpen,
    getCycleFromMsTimestamp,
    getRelativeToCycle,
    getCurrentCycle,
    isReportPeriodOpened,
  };
});
